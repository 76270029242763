<template>
  <content-home />
</template>

<script>
import ContentHome from "@/components/ContentHome.vue";

export default {
  name: "Home",
  components: {
    ContentHome,
  },
};
</script>
