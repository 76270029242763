<template>
  <section>
    <h1 class="text-center m-10">Playtest examples</h1>
    <h2 class="text-center">Triggering moves</h2>
    <article class="grid mt-10 mx-4 justify-items-center">
      <h3 class="mb-2 text-center">Backstab</h3>
      <p class="md:mt-4 md:mx-20 m-2 max-w-2xl">
        In this clip Kazul (Alex) tries to sneak up behind the lizard priest and
        perform Backstab. Alex's character hasn't learned the Backstab move yet,
        but they're allowed to try it at the "wild" level by flipping 3 cards
        and taking the worst result.
      </p>
      <div
        class="grid mt-10 mx-4 grid-flow-row md:grid-flow-col items-center md:justify-items-center"
      >
        <img
          class="w-screen md:w-80 p-4"
          src="../assets/images/cards/move_backstab.png"
          alt="trigger move backstab"
        />
        <iframe
          class="justify-self-center"
          width="300"
          height="200"
          src="https://www.youtube.com/embed/3oWjjZ3BsfI"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </article>
    <article class="grid mt-10 mx-4 justify-items-center">
      <h3 class="mb-2 text-center">I know this</h3>
      <p class="md:mt-4 md:mx-20 m-2 max-w-2xl">
        In this clip, the table discusses possible options for the "Yoshi" PC
        Abigale (Mel), who has lost their sword, and decides they want to get it
        back, even if they must resort to unconventional means.
      </p>
      <div
        class="grid mt-10 mx-4 grid-flow-row md:grid-flow-col items-center md:justify-items-center"
      >
        <img
          class="w-screen md:w-80 p-4"
          src="../assets/images/cards/move_i_know_this.png"
          alt="trigger move I know this"
        />

        <iframe
          class="justify-self-center"
          width="300"
          height="200"
          src="https://www.youtube.com/embed/USjlUck5fnU"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </article>
    <article class="grid mt-10 mx-4 justify-items-center">
      <h3 class="mb-2 text-center">Channel The Living Light</h3>
      <p class="md:mt-4 md:mx-20 m-2 max-w-2xl">
        In this clip, Pronce (Michael) triggers Channel The Living Light, to use
        his magic item, Sling of Unerring Dispatch. After a successful hit, the
        GM and Michael team up to describe the result.
      </p>
      <div
        class="grid mt-10 mx-4 grid-flow-row md:grid-flow-col items-center md:justify-items-center"
      >
        <img
          class="w-screen md:w-80 p-4"
          src="../assets/images/cards/move_channel_the_living_light.png"
          alt="trigger move channel the living light"
        />
        <iframe
          class="justify-self-center"
          width="300"
          height="200"
          src="https://www.youtube.com/embed/UKDmL2gF5Iw"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </article>
    <h2 class="text-center mt-20">Examples of play</h2>
    <article class="mx-4 grid mt-10 justify-items-center">
      <div class="mt-4">
        <h3 class="mb-2 text-center">Start Script</h3>
        <p class="grid-flow-col max-w-2xl">
          This is the Start Script (version as of May 10, 2018, when the working
          title was still "Deckahedron World") that begins a session for players
          that have never played before.
        </p>
      </div>
      <iframe
        class="mt-10 justify-self-center w-full md:w-80"
        width="00"
        height="200"
        src="https://www.youtube.com/embed/Vvnt06UfOr8"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </article>
    <article class="mx-4 grid mt-10 justify-items-center">
      <div class="mt-4">
        <h3 class="mb-2 text-center">Full Session</h3>
        <p class="grid-flow-col max-w-2xl">
          A full session of play (version as of May 10, 2018, when the working
          title was still "Deckahedron World") where the players have never
          played a role-playing game before.
        </p>
      </div>
      <iframe
        class="mt-10 justify-self-center w-full md:w-80"
        width="300"
        height="200"
        src="https://www.youtube.com/embed/K2uRJQa4F8o"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </article>
    <article class="grid mt-10 m-4 justify-items-center">
      <h2>Contribute your own</h2>
      <p class="mt-4 max-w-2xl">
        If you would like to contribute to the examples of play -- either text
        or video -- you can
        <a class="underline text-orange-900 font-semibold" href="sjb@ezide.com"
          >send me an email</a
        >.
      </p>
    </article>
  </section>
</template>
<script>
export default {
  name: "Examples",
};
</script>