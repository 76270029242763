<template>
  <Faq />
</template>
<script>
import Faq from "@/components/Faq";
export default {
  name: "FaqPage",
  components: {
    Faq,
  },
};
</script>