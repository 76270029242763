<template>
  <Downloads />
</template>
<script>
import Downloads from "@/components/Downloads.vue";
export default {
  name: "DownloadsPage",
  components: {
    Downloads,
  },
};
</script>