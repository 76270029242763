<template>
  <Examples />
</template>
<script>
import Examples from "@/components/Examples.vue";
export default {
  name: "ExamplesPage",
  components: {
    Examples,
  },
};
</script>